var render = function render(){var _vm=this,_c=_vm._self._c;return _c('slice',{staticClass:"slice--cta-image",class:[
    _vm.slice.primary.full_page ? '' : 'container',
    _vm.slice.primary.full_page ? 'full' : '',
    `text--${_vm.slice.primary.text_position}`,
    _vm.slice.primary.no_shadow ? 'no-shadow' : '',
  ],attrs:{"data-slice-type":_vm.slice.slice_type,"data-slice-variation":_vm.slice.variation}},[_c('div',{staticClass:"row-md justify-center"},[_c('div',{class:_vm.slice.primary.full_page ? 'col-md-12' : 'col-md-10 col-xlg-12'},[_c(_vm.linkComponent(_vm.slice.primary.link),{tag:"component",attrs:{"to":_vm.url,"href":_vm.url,"data-cy-link":""},nativeOn:{"click":function($event){return _vm.activateLoading.apply(null, arguments)}}},[(_vm.firstSlice)?_c('div',{staticClass:"slice__image p-relative",attrs:{"data-cy-image":""}},[_c('image-simple',{staticClass:"d-none d-md-block",attrs:{"image":_vm.slice.primary.full_page
                ? _vm.slice.primary.image.full
                : _vm.slice.primary.image}}),_vm._v(" "),_c('image-simple',{staticClass:"d-md-none",attrs:{"image":_vm.slice.primary.full_page
                ? _vm.slice.primary.image.full_mobile
                : _vm.slice.primary.image.mobile,"widths":[320, 414, 640, 769]}})],1):_c('div',{staticClass:"slice__image p-relative",attrs:{"data-cy-image":""}},[_c('lazy-image',{ref:"imageDesktop",staticClass:"d-none d-md-block",attrs:{"image":_vm.slice.primary.full_page
                ? _vm.slice.primary.image.full
                : _vm.slice.primary.image}}),_vm._v(" "),_c('lazy-image',{staticClass:"d-md-none",attrs:{"image":_vm.slice.primary.full_page
                ? _vm.slice.primary.image.full_mobile
                : _vm.slice.primary.image.mobile,"widths":[320, 414, 640, 769]}})],1),_vm._v(" "),_c('div',{staticClass:"slice__content"},[(_vm.slice.primary.accroche)?_c('p',{staticClass:"mb-0-6 text-medium w-600 mb-0-6",class:[
              _vm.slice.primary.text_color === 'jaune'
                ? 'text-color-yellow'
                : _vm.slice.primary.text_color === 'bleu'
                ? 'text-color-secondary'
                : 'text-color-tertiary',
            ]},[_vm._v("\n            "+_vm._s(_vm.slice.primary.accroche)+"\n          ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"w-900 mb-1",class:[
              _vm.slice.primary.full_page ? 'h1' : 'h3',
              _vm.slice.primary.text_color === 'jaune'
                ? 'text-color-yellow'
                : _vm.slice.primary.text_color === 'bleu'
                ? 'text-color-secondary'
                : 'text-color-tertiary',
            ]},[_vm._v("\n            "+_vm._s(_vm.slice.primary.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"d-flex",class:[
              _vm.slice.primary.full_page
                ? 'justify-center'
                : _vm.slice.primary.text_position === 'droite'
                ? 'justify-end'
                : '',
              _vm.slice.primary.text_position === 'gauche'
                ? 'justify-md-start'
                : '',
              _vm.slice.primary.text_position === 'centre'
                ? 'justify-md-center'
                : '',
              _vm.slice.primary.text_position === 'droite'
                ? 'justify-md-end'
                : '',
            ]},[_c('div',[_c('primary-button',{ref:"link",attrs:{"type":"button","text":_vm.slice.primary.button_label,"with-arrow":"","aspect":_vm.slice.primary.text_color === 'jaune'
                    ? 'yellow'
                    : _vm.slice.primary.full_page
                    ? 'dark'
                    : 'light',"loading":_vm.loadingButton}})],1)])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }