// définir un objet mixin
const scrollMixin = {
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  data: () => ({
    scrollEventSended: 0,
    percentageOfScreenToSend: [25, 50, 75, 100],
    percentageOfScreenSended: {},
    previousPercentage: 0,
    scrollTimeout: null,
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const scrollPercentage = Math.round(
        (window.scrollY * 100) /
          (document.documentElement.scrollHeight -
            document.documentElement.clientHeight)
      )

      let currentPercentage = 0
      let i = 0
      while (this.percentageOfScreenToSend[i] <= scrollPercentage) {
        currentPercentage = this.percentageOfScreenToSend[i++]
      }

      if (currentPercentage !== this.previousPercentage) {
        clearTimeout(this.scrollTimeout)

        this.scrollTimeout =
          currentPercentage !== 0 &&
          !this.percentageOfScreenSended[currentPercentage]
            ? setTimeout(() => {
                this.$track.scroll(currentPercentage.toString())
                this.percentageOfScreenSended[currentPercentage] = true
              }, 2000)
            : null
        this.previousPercentage = currentPercentage
      }
    },
  },
}

export default scrollMixin
