import * as prismic from '@prismicio/client'

import Product from '@/entities/shopify/Product'
import PrismicProduct from '@/entities/prismic/Product'

import services from '@/services'
import replaceUids from '@/services/utils/replaceUids'

import ShopifyBase64 from '@/utils/shopifyBase64'

import { COLLECTION_PRODUCT_TYPES, TYPES } from '@/const'

export default {
  props: {
    slice: { type: Object, required: true },
    products: { type: Array, default: () => [] },
    auto: { type: Boolean, default: false },
    forceLoading: { type: Boolean, default: false },
  },
  data: () => ({
    emptyPrismicProduct: new PrismicProduct({}),
    prismicProducts: [],
    shopifyProducts: [],
    productList: [],
    loading: {
      init: false,
      shopify: true,
    },
    error: {
      instantiate: false,
      refresh: false,
    },
    allProductAreLoaded: false,
  }),
  computed: {
    productShopifyIds() {
      return [...new Set(this.prismicProducts.map((p) => p.shopifyId))]
    },
  },
  async fetch() {
    if (this.auto) {
      return
    }

    if (this.products.length > 0) {
      if (process.server) {
        this.populateDatas()
      }

      this.loading.init = false

      return
    }

    if (
      this.productUids.length > 0 &&
      this.productList.length !== this.productUids.length
    ) {
      await this.loadProducts()
      await this.getShopifyProducts()
      this.orderPrismicProducts()
    }

    this.loading.init = false
  },
  created() {
    if (this.products.length === 0 || this.forceLoading) {
      this.loading.init = true
    } else if (this.products.length > 0 && this.auto) {
      this.populateDatas()
    }
  },

  async mounted() {
    if (this.auto) {
      return
    }

    if (this.products.length > 0) {
      this.populateDatas()
      await this.refreshShopifyProducts()
    } else if (!this.$fetchState.pending) {
      this.initShopifyProducts()
      await this.refreshShopifyProducts()
    }
  },
  methods: {
    populateDatas() {
      const pp = this.products.filter((p) => {
        if (this.auto) {
          return p.prismic && p.shopify
        }
        return (
          p.prismic &&
          p.shopify &&
          this.productUids.includes(
            replaceUids.replaceAlterWithOrigin(p.prismic.uid)
          )
        )
      })

      this.prismicProducts = pp.map((p) => PrismicProduct.fromJSON(p.prismic))
      this.shopifyProducts = pp.map((p) => new Product(p.shopify))

      this.orderPrismicProducts()
    },

    orderPrismicProducts() {
      const pp = []

      if (this.auto) {
        if (this.config) {
          pp.push(
            ...this.config.product_order
              .map((p) => p.product.uid)
              .filter((uid) =>
                this.prismicProducts.some(
                  (prismicProduct) => prismicProduct.uid === uid
                )
              )
              .map((uid) => {
                const prismicProduct = this.prismicProducts.find(
                  (prismicProduct) => prismicProduct.uid === uid
                )

                const shopifyProduct =
                  this.shopifyProducts.length > 0
                    ? this.shopifyProducts.find(
                        (p) => p.id === prismicProduct.shopifyId
                      )
                    : null

                return {
                  prismicProduct,
                  shopifyProduct,
                }
              })
          )

          const missingProducts = this.prismicProducts.filter(
            (prismicProduct) => {
              return !pp.some(
                (p) => p.prismicProduct.uid === prismicProduct.uid
              )
            }
          )

          if (missingProducts.length > 0) {
            pp.push(
              ...missingProducts.map((prismicProduct) => {
                const shopifyProduct =
                  this.shopifyProducts.length > 0
                    ? this.shopifyProducts.find(
                        (p) => p.id === prismicProduct.shopifyId
                      )
                    : null

                return {
                  prismicProduct,
                  shopifyProduct,
                }
              })
            )
          }
        } else {
          for (const [
            index,
            prismicProduct,
          ] of this.prismicProducts.entries()) {
            const shopifyProduct =
              this.shopifyProducts.length > 0
                ? this.shopifyProducts.find(
                    (p) => p.id === prismicProduct.shopifyId
                  )
                : null

            pp.push({
              prismicProduct,
              shopifyProduct,
              highlight:
                this.slice.primary.products_type ===
                  COLLECTION_PRODUCT_TYPES.NORMAL &&
                this.slice.primary.list_type === 'agenda-bientot-dispo' &&
                index === 0,
            })
          }
        }
      } else {
        for (const productItem of this.productsItems) {
          const uid = productItem.product.uid
          const prismicProduct = this.prismicProducts.find(
            (p) => p.uid === replaceUids.replaceOriginWithAlter(uid)
          )

          if (prismicProduct) {
            const shopifyProduct =
              this.shopifyProducts.length > 0
                ? this.shopifyProducts.find(
                    (p) => p.id === prismicProduct.shopifyId
                  )
                : null

            pp.push({
              prismicProduct,
              shopifyProduct,
              params: this.searchQueryParams(productItem.search_query_params),
              new: productItem.new,
              third: productItem.third_in_line,
              image:
                productItem.image && productItem.image.url
                  ? productItem.image
                  : null,
            })
          }
        }
      }

      this.productList = pp
      this.allProductAreLoaded = true

      if (this.auto) {
        this.loading.init = false
      }
    },
    isSuit(shopifyProduct) {
      const prismicProduct = this.productList.find(
        (p) =>
          p.prismicProduct.shopifyId === ShopifyBase64.getId(shopifyProduct.id)
      )

      if (prismicProduct) {
        return prismicProduct.prismicProduct.type === TYPES.SUIT
      }

      return false
    },

    initShopifyProducts() {
      for (const p of this.productList) {
        const data = this.shopifyProducts.find(
          (r) => p.prismicProduct.shopifyId === ShopifyBase64.getId(r.id)
        )

        if (data) {
          p.shopifyProduct = this.initShopifyProduct(data)
        } else {
          this.error.instantiate = true
          this.$logError.captureException(
            new Error(
              'Instantiate shopify product fail : no data found in shopifyProducts results'
            ),
            {
              shopifyId: p.prismicProduct.shopifyId,
              prismicProducts: JSON.stringify(this.productList),
              shopifyProducts: JSON.stringify(this.shopifyProducts),
            }
          )
        }
      }
    },

    initShopifyProduct(p) {
      return p.variants.edges
        ? new Product(p, this.isSuit(p) ? 'suit' : 'normal')
        : Product.fromJSON(p)
    },

    async loadProducts() {
      if (this.productUids.length > 0) {
        try {
          const products = await this.$prismic.client.get({
            filters: [
              prismic.filter.in(
                'my.product.uid',
                this.productUids.map((uid) =>
                  replaceUids.replaceOriginWithAlter(uid)
                )
              ),
            ],
            pageSize: 100,
            lang: this.$i18n.locale,
            graphQuery: `{
                product {
                  product_name
                  product_type
                  shopify_id
                  status
                  stock_initial
                  image
                  product_bar_image
                  startdate
                  enddate
                  content_hidden
                  marketing
                  marketing_message
                  button_label
                  third_variant_name
                  colors {
                    image_principale
                    image_principale_cdn
                    color_name
                    color_thumbnail
                    third_variant_name
                  }
                  linked_products {
                    label
                    image
                    product_option
                  }
                  agenda_image
                  eshop_image
                }
              }`,
          })

          if (products.results.length > 0) {
            this.prismicProducts = products.results.map(
              (r) => new PrismicProduct(r, true)
            )
          } else {
            this.$logError.captureException(
              new Error('No products return from prismic API')
            )
          }
        } catch (err) {
          this.$logError.captureException(err)
        }
      }
    },

    async getShopifyProducts() {
      this.loading.shopify = true

      try {
        const shopifyProducts =
          await services.productService.getShopifyProducts(
            this.productShopifyIds,
            this.$i18n.locale
          )

        if (shopifyProducts.length > 0) {
          for (const shopifyProduct of shopifyProducts) {
            if (shopifyProduct) {
              this.shopifyProducts.push(this.initShopifyProduct(shopifyProduct))
            }
          }
        }
      } catch (err) {
        this.$logError.captureException(err)
        this.error.refresh = true
        return
      }

      this.loading.shopify = false
    },

    async refreshShopifyProducts() {
      let pp = []

      this.loading.shopify = true

      try {
        pp = await services.productService.refreshShopifyProducts(
          this.productShopifyIds,
          this.$i18n.locale
        )
      } catch (err) {
        this.$logError.captureException(err)
        this.error.refresh = true
        return
      }

      for (const shopifyProductData of pp) {
        if (shopifyProductData.id) {
          const productListItem = this.productList.find(
            (p) =>
              p.shopifyProduct.id === ShopifyBase64.getId(shopifyProductData.id)
          )

          if (
            productListItem &&
            productListItem.shopifyProduct &&
            productListItem.shopifyProduct instanceof Product
          ) {
            productListItem.shopifyProduct.update(shopifyProductData)
          } else {
            this.$logError.captureException(
              new Error('Refresh shopify product fail : no product found'),
              {
                shopifyId: ShopifyBase64.getId(shopifyProductData.id),
                refreshShopifyData: JSON.stringify(shopifyProductData),
                prismicProducts: JSON.stringify(this.prismicProduct),
                shopifyProducts: JSON.stringify(this.shopifyProducts),
              }
            )
          }
        }
      }

      this.loading.shopify = false
    },

    searchQueryParams(params) {
      if (params) {
        const query = {}
        const pp = params.split('&')

        for (const p of pp) {
          const split = p.split('=')
          query[split[0]] = split[1]
        }

        return query
      }

      return null
    },
  },
}
