<template>
  <div v-if="prismicDocument.data.body" class="home collection">
    <slices
      :slices="prismicDocument.data.body"
      :origin="ORIGIN.HOME"
      :entities="{ products }"
    />
  </div>
</template>

<script>
import ScrollTrackingMixin from '@/mixins/ScrollTrackingMixin'
import Slices from '@/components/Slices/Slices'

import services from '@/services'

import { ORIGIN } from '@/const'

export default {
  name: 'HomeContext',
  components: {
    Slices,
  },
  mixins: [ScrollTrackingMixin],
  async asyncData({ payload, $prismic, $logError, i18n, error, params }) {
    if (payload && payload.prismic) {
      return {
        prismicDocument: payload.prismic,
        products: payload.products,
      }
    }

    try {
      // Query to get post content
      const collection = await $prismic.client.getByUID(
        'collection',
        `home-${params.context}`,
        {
          lang: i18n.locale,
        }
      )

      // Returns data to be used in template
      return {
        prismicDocument: collection,
        slices: collection.data.body,
      }
    } catch (e) {
      // Returns error page
      $logError.captureException(e, {
        uid: `home-${params.context}`,
        lang: i18n.locale,
        tags: {
          page: 'home',
        },
      })
      error({
        statusCode: 404,
        message: `Collection home-${params.context} not found for ${i18n.locale}`,
      })
    }
  },
  data: () => ({
    prismicDocument: null,
    products: [],
    ORIGIN,
  }),
  head() {
    if (this.prismicDocument) {
      let title = this.$t('seo.defaultTitle')

      if (this.prismicDocument.data.meta_title) {
        title = this.prismicDocument.data.meta_title
      }

      const route = `${
        process.env.BASE_URL ? process.env.BASE_URL : 'http://localhost:3000'
      }${this.$nuxt.$route.path}`

      const head = services.pageService.getHead(
        {
          type: 'home',
          title,
          route,
          prismic: this.prismicDocument,
          defaultTitle: this.$t('seo.defaultTitle'),
          defaultDesc: this.$t('seo.defaultDesc'),
        },
        this.$generateAlternateLinksMeta(
          this.prismicDocument.alternate_languages
        )
      )

      head.bodyAttrs = {
        class: 'home header-transparent',
      }

      return head
    }

    return {
      bodyAttrs: {
        class: 'home header-transparent',
      },
    }
  },
  mounted() {
    this.$track.page(this.$metaInfo, this.$route, this.$nuxt.context.from)
  },
}
</script>

<style lang="scss">
.home {
  .slices {
    .slice:first-child {
      margin-top: 0;
    }
  }
}
</style>
