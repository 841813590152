<template>
  <div class="marketing-argument">
    <p
      v-if="marketing.type === MARKET_ARGUMENT.TEXTE"
      class="mb-0-6"
      :style="textColor ? { color: textColor } : ''"
    >
      {{ marketing.message }}
    </p>
    <div
      v-else-if="
        productStatus === STATUS.AGENDA || productStatus === STATUS.TEASING
      "
    >
      <p
        v-if="productState.willBeInSell"
        class="h5 text-color-tertiary mb-0-6"
        :style="textColor ? { color: textColor } : ''"
      >
        {{ $d(dates.start, 'short') }}
      </p>
      <p
        v-else
        class="product-card__date text-small mb-0"
        :style="textColor ? { color: textColor } : ''"
      >
        {{ $t('card.product.agenda.status') }}
      </p>
    </div>
    <div
      v-else-if="dates.end && notToLong()"
      class="product-card__argument d-flex justify-center align-baseline mb-0-6"
      :style="textColor ? { color: textColor } : ''"
    >
      <span>{{ $t('purchase.timer.only') }}&nbsp;</span>
      <client-only>
        <count-down
          :end="dates.end"
          :over-text="$t('purchase.timer.over')"
          show-unit
          class="w-600"
        />
      </client-only>
      <span>{{ $t('purchase.timer.left') }}</span>
    </div>

    <p
      v-else
      class="product-card__argument mb-0-6"
      :style="textColor ? { color: textColor } : ''"
    >
      <number-animated
        ref="number"
        :from="numberOfSells.from"
        :to="numberOfSells.to"
        :duration="0.5"
        class="w-600"
        @complete="numberOfSells.from = numberOfSells.to"
      />
      {{ $t('purchase.nbPreco') }}
    </p>
  </div>
</template>

<script>
import { STATUS, MARKET_ARGUMENT } from '@/const'
import { EVENTS } from '@/const/events'

export default {
  name: 'MarketingArgument',
  components: {
    CountDown: () => ({
      component: import(
        '@/components/countdown/CountDown' /* webpackChunkName: 'countdown' */
      ),
    }),
    NumberAnimated: () => ({
      component: import(
        '@/components/number/Number' /* webpackChunkName: 'animated_number' */
      ),
    }),
  },
  props: {
    marketing: {
      type: Object,
      required: true,
    },
    textColor: {
      type: String,
      default: null,
    },
    productStatus: {
      type: String,
      default: STATUS.AGENDA,
      validator: (value) => {
        return Object.values(STATUS).includes(value)
      },
    },
    /**
     * L'état du produit.
     */
    productState: {
      type: Object,
      default: () => ({
        willBeInSell: false,
        willBeInStock: false,
      }),
    },
    /**
     * Dates de mise en vente du Produit
     */
    dates: {
      type: Object,
      default: () => ({ start: undefined, end: undefined }),
    },
    inventory: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    numberOfSells: { from: 0, to: 0 },
    STATUS,
    MARKET_ARGUMENT,
  }),
  beforeDestroy() {
    this.$nuxt.$off(EVENTS.PRODUCT_UPDATED, this.updateNumberOfSells)
  },
  mounted() {
    this.numberOfSells = { from: this.inventory, to: this.inventory }

    this.$nuxt.$on(EVENTS.PRODUCT_UPDATED, this.updateNumberOfSells)
  },
  methods: {
    notToLong() {
      if (this.dates.end) {
        const now = new Date()
        const endTime = this.dates.end.getTime()
        const nowTime = now.getTime()

        if (endTime > nowTime) {
          const timeDuration = endTime - nowTime
          const numberOfDays = Math.floor(timeDuration / (1000 * 60 * 60 * 24))

          if (numberOfDays > 2) {
            return false
          }
        }
      }

      return true
    },
    updateNumberOfSells() {
      this.numberOfSells.to = this.inventory
    },
  },
}
</script>

<style lang="scss">
.marketing-argument {
  .rich-text {
    margin: 0;
  }
}
</style>
