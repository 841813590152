<template>
  <div class="cta-images p-relative" :class="{ container: !isFull }">
    <div :class="{ 'row-md justify-center': !isFull }">
      <div :class="{ 'col-md-10': !isFull }">
        <div v-if="loading" class="skeleton" style="height: 100vh" />
        <div v-else class="slice__inner d-grid" :class="{ 'no-gap': noGap }">
          <nuxt-link
            v-for="item of items"
            :key="item.id"
            :to="{
              path: $contextPrismicPath(item),
              query: item.query,
              hash: item.anchor ? `#${item.anchor}` : null,
            }"
            class="slice__cta d-block p-relative"
            @click.native="loadingItem = item.id"
          >
            <lazy-image
              v-if="
                !isFull &&
                noGap &&
                item.image &&
                item.image.half_no_gap &&
                item.image.half_no_gap.url
              "
              :image="item.image.half_no_gap"
              sizes="600px"
              class="d-none d-md-block"
            />
            <lazy-image
              v-else-if="
                !isFull && item.image && item.image.half && item.image.half.url
              "
              :image="item.image.half"
              sizes="570px"
              class="d-none d-md-block"
            />
            <lazy-image
              v-else-if="item.image && item.image.url"
              :image="item.image"
              :widths="imageWidths"
              :sizes="`${imageSize}vw`"
              class="d-none d-md-block"
            />

            <lazy-image
              v-if="
                isFull &&
                items.length === 3 &&
                item.image &&
                item.image.highlight &&
                item.image.highlight.url
              "
              :image="item.image.highlight"
              :widths="[640, 960, 1280]"
              class="d-md-none"
              sizes="100vw"
            />
            <lazy-image
              v-else-if="item.image && item.image.url"
              :image="item.image"
              :widths="[640, 960, 1280]"
              class="d-md-none"
              sizes="100vw"
            />

            <div
              v-if="!oneTitle"
              class="slice__cta__content text-color-tertiary"
            >
              <marketing-argument
                v-if="item.type === 'product' && !loadingShopify"
                :marketing="item.marketing"
                :product-status="item.status"
                :product-state="{ willBeInSell: item.dates.start !== null }"
                :dates="item.dates"
                :inventory="
                  item.shopifyProduct
                    ? item.shopifyProduct.totalInventory + item.stockInitial
                    : 0
                "
                class="lh-md-1 mb-0-4"
                :class="{
                  'text-md-secondary text-uppercase w-600 w-md-400': isFull,
                }"
              />
              <p
                v-else-if="item.text"
                class="marketing-argument lh-md-1 text-md-secondary text-uppercase w-600 w-md-400 mb-0-4"
              >
                {{ item.text }}
              </p>
              <p
                class="h3 mb-0-6 mb-md-0-4 text-color-tertiary"
                v-html="item.name"
              ></p>

              <p
                v-if="!isFull && item.shopifyProduct && !loadingShopify"
                class="product-card__price d-flex align-center justify-center justify-md-start w-600 mb-0"
              >
                <strong class="compare-at-price mr-0-6"
                  >{{ item.shopifyProduct.compareAtPrice }}&nbsp;&euro;</strong
                >
                <strong>{{ item.shopifyProduct.price }}&nbsp;&euro;</strong>
              </p>

              <div
                v-if="!isFull"
                class="d-flex justify-center justify-md-start mt-1-5 mt-md-2"
              >
                <primary-button
                  type="nuxt-link"
                  :to="$contextPrismicPath(item)"
                  :text="item.buttonLabel"
                  with-arrow
                  :loading="loadingItem === item.id"
                />
              </div>
              <div v-else class="d-flex">
                <transition name="loading">
                  <span v-if="loadingItem === item.id">
                    <icon-loading />
                  </span>
                </transition>
                <icon name="arrow-right" />
              </div>
            </div>
          </nuxt-link>
        </div>

        <div
          v-if="oneTitle && items.length > 0"
          class="slice__cta__content slice__cta__content--one-title text-color-tertiary text-center"
          :class="`items-${items.length}`"
        >
          <marketing-argument
            v-if="oneProduct && !loadingShopify"
            :marketing="items[0].marketing"
            :product-status="items[0].status"
            :product-state="{ willBeInSell: items[0].dates.start !== null }"
            :dates="items[0].dates"
            :inventory="
              items[0].shopifyProduct
                ? items[0].shopifyProduct.totalInventory + items[0].stockInitial
                : 0
            "
            class="lh-md-1 mb-0-4"
          />
          <div
            v-else-if="text"
            class="marketing-argument text-big mb-0-4"
            v-html="primaryText"
          />
          <h1
            v-if="title"
            class="slice__cta__title mb-0-6 mb-md-0-4 text-color-tertiary"
          >
            {{ title }}
          </h1>

          <p
            v-if="withPrice && items[0].shopifyProduct && !loadingShopify"
            class="product-card__price d-flex align-center justify-center w-600 mb-0"
          >
            <strong class="compare-at-price mr-1"
              >{{ items[0].shopifyProduct.compareAtPrice }}&nbsp;&euro;</strong
            >
            <strong>{{ items[0].shopifyProduct.price }}&nbsp;&euro;</strong>
          </p>

          <div class="d-flex justify-center mt-1-5 mt-md-2">
            <primary-button
              type="nuxt-link"
              :to="$contextPrismicPath(items[0])"
              :text="buttonLabel"
              with-arrow
              :loading="loadingItem !== null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import * as prismic from '@prismicio/client'

import LazyImage from '@/components/Image/LazyImage'
import MarketingArgument from '@/components/Product/MarketingArgument'
import Icon from '@/components/icons/Icon'
import IconLoading from '@/components/icons/Loading'
import PrimaryButton from '@/components/buttons/Primary'

export default {
  name: 'CTAImages',
  components: {
    LazyImage,
    MarketingArgument,
    Icon,
    IconLoading,
    PrimaryButton,
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingShopify: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    oneTitle: {
      type: Boolean,
      default: false,
    },
    oneProduct: {
      type: Boolean,
      default: false,
    },
    withPrice: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: Array,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: null,
    },
    noGap: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingItem: null,
  }),
  computed: {
    primaryText() {
      return prismic.isFilled.richText(this.text)
        ? prismic.asHTML(this.text)
        : ''
    },
    imageSize() {
      return this.$vssWidth / this.items.length
    },
    imageWidths() {
      return [this.imageSize, this.imageSize * 2, this.imageSize * 3]
    },
  },
}
</script>

<style lang="scss">
.cta-images {
  .slice__inner {
    grid-gap: 0;
    grid-template-rows: repeat(auto-fit, minmax(#{rem(200px)}, 1fr));

    @include mq($from: tablet) {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }
  }

  .slice__cta {
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: RGB(0 0 0 / 0.2);
    }

    &__content {
      position: absolute;
      bottom: calc(var(--spacing) * 2);
      left: calc(var(--spacing) * 2);
      z-index: 1;
      pointer-events: none;

      @include mq($from: tablet) {
        bottom: calc(var(--spacing) * 4);
      }

      .icon {
        --icon-size: #{rem(18px)};

        @include mq($from: tablet) {
          --icon-size: #{rem(24px)};
        }

        width: var(--icon-size);
        height: var(--icon-size);
        transition: transform 0.3s ease-out;
      }

      &--one-title {
        left: 50%;
        bottom: 30vh;
        transform: translateX(-50%);
        width: 100%;

        @include mq($from: tablet) {
          bottom: 20vh;
        }

        &.items-2 {
          bottom: 50%;
          transform: translate(-50%, 50%);
        }
      }

      .action {
        pointer-events: all;
      }
    }

    img {
      transition: all 0.3s ease-in-out;
    }

    @include on-hover-and-focus {
      img {
        transform: scale(1.1);
      }
    }
  }

  &:not(.container) {
    .slice__inner {
      min-height: 100vh;
    }

    .slice__cta {
      @include mq($from: tablet) {
        min-height: 100vh;
      }

      @include on-hover-and-focus {
        .icon {
          transform: translateX(1rem);
        }
      }

      &__title {
        font-size: var(--countdown-font-size);
      }

      &__content {
        .marketing-argument {
          .product-card__argument {
            justify-content: flex-start;
          }
        }

        &--one-title {
          padding: 0 var(--spacing);

          .marketing-argument {
            @include mq($until: tablet) {
              font-size: var(--small-font-size);
            }

            .product-card__argument {
              justify-content: center;
            }
          }
        }
      }
    }

    .marketing-argument {
      font-size: rem(10px);
      letter-spacing: 0.02em;

      @include mq($from: tablet) {
        font-size: rem(18px);
      }

      * {
        margin-bottom: 0;
      }
    }

    .lazy-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 0 !important;

      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &.container {
    .slice__inner {
      grid-gap: calc(var(--spacing) * 2);

      @include mq($from: tablet) {
        grid-gap: calc(var(--spacing) * 4);
      }

      &.no-gap {
        grid-gap: 0;
      }
    }

    .slice__cta__content {
      padding: 0 var(--spacing);
    }

    .slice__cta {
      @include mq($until: tablet) {
        text-align: center;

        &__content {
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          padding: 0 var(--spacing);

          &--one-title {
            transform: translate(-50%, 50%);

            .product-card__argument {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
</style>
