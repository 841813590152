var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cta-images p-relative",class:{ container: !_vm.isFull }},[_c('div',{class:{ 'row-md justify-center': !_vm.isFull }},[_c('div',{class:{ 'col-md-10': !_vm.isFull }},[(_vm.loading)?_c('div',{staticClass:"skeleton",staticStyle:{"height":"100vh"}}):_c('div',{staticClass:"slice__inner d-grid",class:{ 'no-gap': _vm.noGap }},_vm._l((_vm.items),function(item){return _c('nuxt-link',{key:item.id,staticClass:"slice__cta d-block p-relative",attrs:{"to":{
            path: _vm.$contextPrismicPath(item),
            query: item.query,
            hash: item.anchor ? `#${item.anchor}` : null,
          }},nativeOn:{"click":function($event){_vm.loadingItem = item.id}}},[(
              !_vm.isFull &&
              _vm.noGap &&
              item.image &&
              item.image.half_no_gap &&
              item.image.half_no_gap.url
            )?_c('lazy-image',{staticClass:"d-none d-md-block",attrs:{"image":item.image.half_no_gap,"sizes":"600px"}}):(
              !_vm.isFull && item.image && item.image.half && item.image.half.url
            )?_c('lazy-image',{staticClass:"d-none d-md-block",attrs:{"image":item.image.half,"sizes":"570px"}}):(item.image && item.image.url)?_c('lazy-image',{staticClass:"d-none d-md-block",attrs:{"image":item.image,"widths":_vm.imageWidths,"sizes":`${_vm.imageSize}vw`}}):_vm._e(),_vm._v(" "),(
              _vm.isFull &&
              _vm.items.length === 3 &&
              item.image &&
              item.image.highlight &&
              item.image.highlight.url
            )?_c('lazy-image',{staticClass:"d-md-none",attrs:{"image":item.image.highlight,"widths":[640, 960, 1280],"sizes":"100vw"}}):(item.image && item.image.url)?_c('lazy-image',{staticClass:"d-md-none",attrs:{"image":item.image,"widths":[640, 960, 1280],"sizes":"100vw"}}):_vm._e(),_vm._v(" "),(!_vm.oneTitle)?_c('div',{staticClass:"slice__cta__content text-color-tertiary"},[(item.type === 'product' && !_vm.loadingShopify)?_c('marketing-argument',{staticClass:"lh-md-1 mb-0-4",class:{
                'text-md-secondary text-uppercase w-600 w-md-400': _vm.isFull,
              },attrs:{"marketing":item.marketing,"product-status":item.status,"product-state":{ willBeInSell: item.dates.start !== null },"dates":item.dates,"inventory":item.shopifyProduct
                  ? item.shopifyProduct.totalInventory + item.stockInitial
                  : 0}}):(item.text)?_c('p',{staticClass:"marketing-argument lh-md-1 text-md-secondary text-uppercase w-600 w-md-400 mb-0-4"},[_vm._v("\n              "+_vm._s(item.text)+"\n            ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"h3 mb-0-6 mb-md-0-4 text-color-tertiary",domProps:{"innerHTML":_vm._s(item.name)}}),_vm._v(" "),(!_vm.isFull && item.shopifyProduct && !_vm.loadingShopify)?_c('p',{staticClass:"product-card__price d-flex align-center justify-center justify-md-start w-600 mb-0"},[_c('strong',{staticClass:"compare-at-price mr-0-6"},[_vm._v(_vm._s(item.shopifyProduct.compareAtPrice)+" €")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(item.shopifyProduct.price)+" €")])]):_vm._e(),_vm._v(" "),(!_vm.isFull)?_c('div',{staticClass:"d-flex justify-center justify-md-start mt-1-5 mt-md-2"},[_c('primary-button',{attrs:{"type":"nuxt-link","to":_vm.$contextPrismicPath(item),"text":item.buttonLabel,"with-arrow":"","loading":_vm.loadingItem === item.id}})],1):_c('div',{staticClass:"d-flex"},[_c('transition',{attrs:{"name":"loading"}},[(_vm.loadingItem === item.id)?_c('span',[_c('icon-loading')],1):_vm._e()]),_vm._v(" "),_c('icon',{attrs:{"name":"arrow-right"}})],1)],1):_vm._e()],1)}),1),_vm._v(" "),(_vm.oneTitle && _vm.items.length > 0)?_c('div',{staticClass:"slice__cta__content slice__cta__content--one-title text-color-tertiary text-center",class:`items-${_vm.items.length}`},[(_vm.oneProduct && !_vm.loadingShopify)?_c('marketing-argument',{staticClass:"lh-md-1 mb-0-4",attrs:{"marketing":_vm.items[0].marketing,"product-status":_vm.items[0].status,"product-state":{ willBeInSell: _vm.items[0].dates.start !== null },"dates":_vm.items[0].dates,"inventory":_vm.items[0].shopifyProduct
              ? _vm.items[0].shopifyProduct.totalInventory + _vm.items[0].stockInitial
              : 0}}):(_vm.text)?_c('div',{staticClass:"marketing-argument text-big mb-0-4",domProps:{"innerHTML":_vm._s(_vm.primaryText)}}):_vm._e(),_vm._v(" "),(_vm.title)?_c('h1',{staticClass:"slice__cta__title mb-0-6 mb-md-0-4 text-color-tertiary"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.withPrice && _vm.items[0].shopifyProduct && !_vm.loadingShopify)?_c('p',{staticClass:"product-card__price d-flex align-center justify-center w-600 mb-0"},[_c('strong',{staticClass:"compare-at-price mr-1"},[_vm._v(_vm._s(_vm.items[0].shopifyProduct.compareAtPrice)+" €")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.items[0].shopifyProduct.price)+" €")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center mt-1-5 mt-md-2"},[_c('primary-button',{attrs:{"type":"nuxt-link","to":_vm.$contextPrismicPath(_vm.items[0]),"text":_vm.buttonLabel,"with-arrow":"","loading":_vm.loadingItem !== null}})],1)],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }