<template>
  <slice
    class="slice--baniere"
    :class="{ shadow: slice.primary.image_shadow, 'with-arrow': hasArrow }"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
  >
    <lazy-image
      v-if="imageHeightAutoMobile"
      ref="image"
      :image="slice.primary.image.auto_mobile"
      :image-cdn="slice.primary.image_cdn"
      class="d-md-none"
    />
    <lazy-image
      v-if="imageHeightAuto"
      ref="image"
      :image="slice.primary.image.auto"
      :image-cdn="slice.primary.image_cdn"
      class="slice--baniere__image d-none d-md-block"
    />
    <lazy-image
      v-if="!imageHeightAuto"
      ref="imageMobile"
      :image="slice.primary.image.mobile"
      :image-cdn="slice.primary.image_cdn"
      class="slice--baniere__image d-md-none"
      :widths="[390, 780, 1170]"
    />
    <lazy-image
      v-if="!imageHeightAuto"
      ref="imageDesktop"
      :image="slice.primary.image"
      :image-cdn="slice.primary.image_cdn"
      class="slice--baniere__image d-none d-md-block d-xlg-none"
      :widths="[1440, 2160, 2880]"
    />
    <lazy-image
      v-if="!imageHeightAuto"
      ref="imageLarge"
      :image="slice.primary.image.large"
      :image-cdn="slice.primary.image_cdn"
      class="slice--baniere__image d-none d-xlg-block"
      :widths="[1920, 2880, 3840]"
    />

    <div v-if="hasTitle" class="container">
      <div class="d-flex flex-direction-column" :class="placementClass">
        <prismic-title
          ref="title"
          :title="slice.primary.title"
          class="slice--baniere__title mb-0-4 mb-md-1"
        />

        <rich-text
          v-if="hasDescription"
          ref="desc"
          :content="slice.primary.description"
          class="slice--baniere__desc text-secondary mt-0-4 mt-md-0"
        />

        <div
          v-if="hasArrow"
          ref="icon"
          class="slice--baniere__icon pt-0-4 mt-1"
        >
          <icon name="arrow-down" />
        </div>
      </div>
    </div>
  </slice>
</template>

<script>
import * as prismic from '@prismicio/client'

import Slice from '@/components/Slices/Slice'
import LazyImage from '@/components/Image/LazyImage'
import PrismicTitle from '@/components/Title/PrismicTitle'
import RichText from '@/components/RichText/RichText'
import Icon from '@/components/icons/Icon'

import { TEXT_POSITION } from '@/const'

export default {
  name: 'BaniereSlice',
  components: { Slice, LazyImage, PrismicTitle, RichText, Icon },
  props: {
    slice: { type: Object, required: true },
    firstSlice: { type: Boolean, default: false },
  },
  head() {
    if (this.firstSlice && this.slice.primary.is_top_of_image_light) {
      return {
        htmlAttrs: {
          class: ['header--dark'],
        },
      }
    }
    return {}
  },
  computed: {
    hasTitle() {
      return prismic.isFilled.title(this.slice.primary.title)
    },
    hasDescription() {
      return prismic.isFilled.richText(this.slice.primary.description)
    },
    hasArrow() {
      return this.slice.primary.show_arrow
    },
    placementClass() {
      if (this.slice.primary.text_position === TEXT_POSITION.LEFT) {
        return 'align-start text-md-right'
      }

      if (this.slice.primary.text_position === TEXT_POSITION.RIGHT) {
        return 'align-end text-md-left'
      }

      return 'align-center'
    },
    imageHeightAutoMobile() {
      return (
        this.slice.primary.image_height_auto &&
        this.slice.primary.image.auto_mobile
      )
    },
    imageHeightAuto() {
      return (
        this.slice.primary.image_height_auto && this.slice.primary.image.auto
      )
    },
  },
  mounted() {
    this.adaptSizes()
  },
  methods: {
    adaptSizes() {
      if (this.$refs.title) {
        const w = this.$refs.title.$el.offsetWidth

        if (this.$refs.desc) {
          this.$refs.desc.$el.style.maxWidth = `${w}px`
        }
      }
    },
  },
}
</script>

<style lang="scss">
.slice--baniere {
  position: relative;

  &__image {
    @include mq($from: tablet, $until: large) {
      max-height: 72svh;
    }

    @include mq($from: large) {
      max-height: 75vh;
    }
  }

  h1 {
    font-size: var(--jumbo-font-size);
    letter-spacing: 0.06em;

    @include mq($from: tablet) {
      letter-spacing: 0.01em;
    }
  }

  &__desc {
    font-size: var(--big-font-size);
    line-height: 1.3;
    max-width: rem(500px);
    min-width: rem(240px);
    text-align: justify;
  }

  &__icon {
    .icon {
      height: rem(20px);
    }
  }

  .container {
    padding: calc(var(--spacing) * 1.4) 0 calc(var(--spacing) * 0.4);

    @include mq($from: tablet) {
      padding: calc(var(--spacing) * 2) 0 0;
    }
  }

  &.with-arrow {
    .container {
      @include mq($from: tablet) {
        padding-bottom: calc(var(--spacing) * 0.6);
      }
    }
  }
}
</style>
